import { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import SJlogo from '../../assests/images/SJlogo.svg';
import './navbar.css';


function NavbarHeader() {
 
  const [dropdownopen,setdropdownopen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const handledropdownopen=()=>{
    setdropdownopen(!dropdownopen)
  }

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={`container-fluid ${isSticky ? 'sticky' : ''}`} >
      <nav className='navbar navbar-expand-lg navbar-light pt-4'>
  <div className="container-fluid">
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
    <div className="row container-fluid mx-4">
    <div className="col-lg-5 d-flex justify-content-between logoalign">
    <Link to="/"><img src={SJlogo} alt="SJlogo" /></Link>
    </div>
      <div className="col-lg-6 mt-3">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-between navbartext">
        <li className="nav-item">
          <a className="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#aboutus">About us</a>
        </li>
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" 
          data-bs-toggle="dropdown" aria-expanded="false"
          onClick={()=> handledropdownopen()}>
          Our services
          </a>
          <ul className={`dropdown-menu ${dropdownopen ? 'd-block' : 'd-none'}`}  aria-labelledby="navbarDropdown">
            <li><Link to="/mobileservice" style={{textDecoration:'none'}} onClick={()=> setdropdownopen(false)}><a className="dropdown-item" href="#" >Mobile App Development</a></Link></li>
            <li className='px-3'><hr className="dropdown-divider" /></li>
            <li><Link to="/webservice" style={{textDecoration:'none'}} onClick={()=> setdropdownopen(false)}><a className="dropdown-item" href="#">Website Development</a></Link></li>
            <li className='px-3'><hr className="dropdown-divider" /></li>
            <li><Link to="/digitalservice" style={{textDecoration:'none'}} onClick={()=> setdropdownopen(false)}><a className="dropdown-item" href="#">Digital Marketing</a></Link></li>
          </ul>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#product">Career</a>
        </li>
        <li className="nav-item">
          <a className="nav-link" href="#footer">Contactus</a>
        </li>
        <li className="nav-item">
          <button type="primary" className='getIntouchbtn mt-1'>
          <a className="nav-link" href="#getintouch" style={{padding: '4px 2px',fontSize: '15px'}}>Get In Touch</a>
          </button>
        </li>
      </ul>
    </div>
   
    </div>
    </div>
  </div>
      </nav>
    </div>
  );
}

export default NavbarHeader;
