
import React,{useState} from 'react';
import './App.css';
import NavbarHeader from './components/navbar/navbar';
import BussinessIdea from './components/bussinessIdea/bussinesidea';
import Webdevelopment from './components/services/webdevelopment';
import MobileDevelop from './components/services/mobiledevelopment';
import DigitalMarket from './components/services/digitalmarketing';
import Footer from './components/footer/footer';
import Contactus from './components/services/contactus';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';


function App() {
  return (
    <div className='App'>
       <Router>
    {/* <NavbarHeader /> */}
      <Routes>
         <Route path='/' element={<BussinessIdea/>} />
          <Route path="/webservice" element={<Webdevelopment />} />
          <Route path="/mobileservice" element={<MobileDevelop />} />
          <Route path="/digitalservice" element={<DigitalMarket />} />
          <Route path="/contactus" element={<Contactus />} />
        </Routes>
      <Footer/>
        </Router>
    </div>
  );
}

export default App;



