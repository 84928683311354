import footerlogo from '../../assests/images/footerlogo.svg';
import './footer.css';

function Footer() {
    return (

       

<div className='footerDiv bgImgFooter row container-fluid m-0' id="footer">
            <div className='col-md-4 footerLabel1 text-start'>
                <img src={footerlogo} alt="img" width={140} className='my-4'/>
                <p className='text-start'>Rent A Desk, Madhapur, HI-TECH City,<br/>
                    Hyderabad, Telangana,
                    Pin: 500081</p>
                <p className='text-start'>
                    sales@vendventure.in<br />
                    Phone:  +91 99854 02433
                </p>
            </div>
            <div className='col-md-3 footerlabel2'>
                <p className='text-start plabel'>Services</p>
                <p className='text-start'>Web Design/Development </p>
                    <p className='text-start'>App Development</p>
                    <p className='text-start'>UI/UX Design</p>
                   <p className='text-start'>Digital Marketing</p>
                   <p className='text-start'>Contact Us</p>
            </div>
            <div className='col-md-4 footerlist'>
                <p className='text-start mb-2'>Subscribe Us</p>
                <div className='plabel2'></div>
                <p className='text-start'>It is a long established 
                fact that a reader will be distracted by the readable </p>
                <div className='d-flex'>
                <input type="text" placeholder='Email' className='inputEmail'/>
                 <button type="button" class="btn btn-primary mx-2 px-4">Submit</button>
                </div>
                <p className='text-start mt-3'><strong>Get in Touch</strong></p>
                <div className='d-flex gap-3'>
                <a href="#" className="fa fa-linkedin socialmediaIcons"></a>
                <a href="#" className="fa fa-instagram socialmediaIcons"></a>
                <a href="#" className="fa fa-facebook socialmediaIcons"></a>
                <a href="#" className="fa fa-youtube socialmediaIcons"></a>
                </div>
                </div>
            <div className='col hrLine'></div>
            <p className='text-center footerights'>SriJay Software Solutions Pvt Ltd copyright © 2023. All Rights Reserved.</p>
        </div>
    
       
    );
}

export default Footer;
