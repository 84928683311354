import logo1 from '../../assests/images/logo1.png'
import './bussinesidea.css';
import AboutUs from '../aboutus/aboutus';
import FunFact from '../funfact/funfact';
import DeveloProduct from '../develop/develop';
import Planningservices from '../planningservices/planningservices';
import Team from '../team/team';
import Testimonial from '../testimonial/testimonial';
import GetIntouch from '../getintouch/getintouch';
import Blog from '../blogs/blogs';
import Career from '../career/career';
import NavbarHeader from '../navbar/navbar';


function BussinessIdea() {
  return (
    <div >

      
<div className='bgImgBussiness'>

<NavbarHeader /> 
<div className="row BusinessDiv">
  
    <div className='col-md-6 text-start bgDivLight paraalign'>
      <h2 className='m-0 headerLabels1'>\ We Are Here \</h2>
      <h2 className='text-dark headerLabels my-2'>We provide Best <br/>Bussiness ideas</h2>
      <p className='text-dark paragraph my-4'>We do our best to implement your ideas into <br/>
      the project to make it successful and profitable</p>
     <div className='d-flex'>
        <button type="" className='buttonprimary me-3 px-4 py-2'>View more</button>
     </div>
    </div>
    <div className="col-md-6">
     <img src={logo1} alt="streetjob" width={434}/>
    </div>
   
  </div> 
  <div className='borderLine'></div>
</div>

  <AboutUs/>
  <FunFact/>
   <DeveloProduct/>
      <Planningservices />
      <Team/>
      <Testimonial />
      <GetIntouch/>
      <Blog />
      <Career/>
  </div>
  );
}

export default BussinessIdea;
