import Maskgroup from '../../assests/images/Maskgroup1.svg';
import Blub from '../../assests/images/blub.svg';
import Black from '../../assests/images/Black.svg';
import '../aboutus/aboutus.css';
import '../funfact/funfact.css';
import './develop.css';

function DeveloProduct() {
  return (
    <div className='bgImgDevelop'>
      
      <div class="row developDiv">
    <div class="col-lg-6 text-start mt-3">
    <img src={Maskgroup} alt="banner" className='img-fluid'/>
    </div>
    <div class='m-0 bgStreeDivLight col-lg-6 mt-4'>
        <h2 className='m-0 headerLabels1 text-start'>\ What We Do \</h2>
        <h2 className='my-3 mx-0 text-dark text-start headerLabels'>We Develope Product That  
        <br/>People Love to Use.</h2>
        <p className='m-0 text-dark text-start paragraphtext'>It is a long established fact that a reader will be distracted by the 
          readable content of a page when looking at its layout. The point of 
          using Lorem Ipsum is that it has a more-or-less normal distribution 
          of letters, as opposed, Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed</p>
        <h2 className='m-0 headerLabels1 text-start mt-3'>\ OUR VISION \</h2>
        <h2 className='m-0 text-dark text-start headerLabels mt-2'>Digital Transformation</h2>
        <p className='m-0 text-dark text-start paragraphtext mt-2'>
          We places a strong emphasis on innovation, indicating its commitment to developing and delivering cutting-edge IT solutions. It aims to stay ahead of technological advancements and offer state-of-the-art products and services.
          </p>
         <div className='row mt-4'>
         <div className='col-lg-6 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={Blub} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard p-2 pe-1">
                    <h5 className='text-start mb-0 cardheader'>Our Vision</h5>
                    <p className='text-start mb-0 mt-0'>Empowering the world 
                    through innovative software 
                    solutions.</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-6 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={Black} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard p-2 pe-1">
                    <h5 className='text-start mb-0 cardheader'>Our Goal</h5>
                    <p className='text-start mb-0 mt-0'>To revolutionize industries 
                        and drive positive change
                        through cutting-edge technology</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
         </div>
    </div>
    
  </div>
    </div>
  // <div class="row containerDiv">
  //   <div class="col-lg-6 text-start mt-3">
  //   <img src={Maskgroup} alt="banner" width={460}/>
  //   </div>
  //   <div class='m-0 bgStreeDivLight col-lg-6 mt-4'>
  //       <h2 className='m-0 headerLabels1 text-start'>\ What We Do \</h2>
  //       <h2 className='my-3 mx-0 text-dark text-start headerLabels'>We Develope Product That  
  //       <br/>People Love to Use.</h2>
  //       <p className='m-0 text-dark text-start paragraphtext'>It is a long established fact that a reader will be distracted by the 
  //         readable content of a page when looking at its layout. The point of 
  //         using Lorem Ipsum is that it has a more-or-less normal distribution 
  //         of letters, as opposed, Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed</p>
  //       <h2 className='m-0 headerLabels1 text-start mt-3'>\ OUR VISION \</h2>
  //       <h2 className='m-0 text-dark text-start headerLabels mt-2'>Digital Transformation</h2>
  //       <p className='m-0 text-dark text-start paragraphtext mt-2'>
  //         We places a strong emphasis on innovation, indicating its commitment to developing and delivering cutting-edge IT solutions. It aims to stay ahead of technological advancements and offer state-of-the-art products and services.
  //         </p>
  //        <div className='row mt-4'>
  //        <div className='col-lg-6 d-flex'>
  //           <div className={'text-dark card mb-3 cardRadius funcard bg-white'} >
  //               <div className="row g-0">
  //                   <div className="col-4">
  //                   <img src={Blub} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
  //                   </div>
  //                   <div className="col-8">
  //                   <div className="card-body statuscard p-2 pe-1">
  //                   <h5 className='text-start mb-0 cardheader'>Our Vision</h5>
  //                   <p className='text-start mb-0 mt-0'>Empowering the world 
  //                   through innovative software 
  //                   solutions.</p>
  //                   </div>
  //                   </div>
  //               </div>
  //               </div>
  //           </div>
  //           <div className='col-lg-6 d-flex'>
  //           <div className={'text-dark card mb-3 cardRadius funcard bg-white'} >
  //               <div className="row g-0">
  //                   <div className="col-4">
  //                   <img src={Black} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
  //                   </div>
  //                   <div className="col-8">
  //                   <div className="card-body statuscard p-2 pe-1">
  //                   <h5 className='text-start mb-0 cardheader'>Our Goal</h5>
  //                   <p className='text-start mb-0 mt-0'>To revolutionize industries 
  //                       and drive positive change
  //                       through cutting-edge technology</p>
  //                   </div>
  //                   </div>
  //               </div>
  //               </div>
  //           </div>
  //        </div>
  //   </div>
    
  // </div>
  );
}

export default DeveloProduct;
