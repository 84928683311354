import banner from '../../assests/images/banner.svg';
import userIcon from '../../assests/images/userIcon.svg';
import './aboutus.css';


function AboutUs() {
  return (
    
    <div className='bgAboutus'>
       <div className="row aboutusContainer">
    <div class="col-lg-6 text-start aboutusDiv mt-4">
    <img src={banner} alt="banner" width="100%"/>
    <div className='advicecard d-flex'>
      <div className='iconCard'>
      <img src={userIcon} alt="card" style={{width: '40px',height: '35px'}}/>
      </div>
       <div className='mx-3'>
        <p className='m-0 carddetails'>Get Instant Professional Advice</p>
        <p className='my-2' style={{fontSize:'14px'}}>Ready to Help : <span style={{color:'#2066AE'}}>+91 356 678 7897</span></p>
       </div>
    </div>
    </div>
    <div class='m-0 bgStreeDivLight col-lg-6 mt-4'>
        <h2 className='m-0 headerLabels1 text-start'>\ About us \</h2>
        <h2 className='my-3 mx-0 text-dark text-start headerLabels'>One of the Fastest Way to 
        <br/>Application Development</h2>
        <p className='m-0 text-dark text-start paragraphtext'>SriJay Software Solutions is mobile app development company trusted by many clients across Globe. Our expert team of iOS & Android mobile app developers have been developing productive mobile apps for more than 14 years. We take your ideas and turn it into digital product that grows your business.</p>
        <p className='m-0 text-dark text-start paragraphtext my-2'>A strong creative vision embraces innovation and experimentation. It encourages pushing boundaries, exploring new technologies, and finding unique solutions to deliver standout digital experiences that captivate clients.</p>
        <p className='m-0 text-dark text-start paragraphtext mb-3'>Digital experience involves a combination of imagination, user-centricity, visual design, storytelling, and technical expertise. By leveraging these elements, you can create transformative digital experiences that engage, inspire, and leave a lasting impression on clients.</p>
        <h2 className='m-0 headerLabels1 text-start'>\ OUR VISION \</h2>
        <h2 className='m-0 text-dark text-start headerLabels mt-2'>We build innovation & excellence</h2>
        <p className='m-0 text-dark text-start paragraphtext mt-2'>
          We places a strong emphasis on innovation, indicating its commitment to developing and delivering cutting-edge IT solutions. It aims to stay ahead of technological advancements and offer state-of-the-art products and services.
          </p>

    </div>
    
  </div>
    </div>

  );
}

export default AboutUs;
