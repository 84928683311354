import call from '../../assests/images/call.svg';
import email from '../../assests/images/email.svg';
import location from '../../assests/images/location.svg';
import linkedin from  '../../assests/images/linkedin.png';
import instagram from  '../../assests/images/instagram.png';
import facebook from  '../../assests/images/facebook.png';
import twitter from  '../../assests/images/twitter.png';
import './getintouch.css';


function GetIntouch() {
  return (
    <div className='row bgImgGetIntouch container-fluid m-0'>
  <div class="getincontainerDiv">
  <h2 className='mt-4 headerLabels1 text-center'>\ Get In Touch \</h2>
  <h2 className='my-2 text-center labeltext mb-4'>Hey! Let’s Talk, Get a Free Consultation Now</h2>
  <div className='row contactbottom'>
    <div className='col-7 box1 me-3'>
      <input type="text" placeholder='Name' className='contactinputfield'/>
      <input type="text" placeholder='Email' className='contactinputfield'/>
      <input type="text" placeholder='Phone' className='contactinputfield'/>
      <textarea type="text" placeholder='Your message' className='contactinputfield'/>
      <div className='text-start ms-3 my-4'>
      <button type="button" className='primary submitbtn'>Submit</button>
      </div>
    </div>
    <div className='col-4 box1'>
        <div className='row'>
        <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                      <div>
                      <img src={call} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                      </div>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Call Anytime</h5>
                    <p className='text-start mb-0 mt-0'>+91 99854 02433</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={email} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Send Email</h5>
                    <p className='text-start mb-0 mt-0'>sales@sjsolutions.in</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={location} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Visit Us</h5>
                    <p className='text-start mb-0 mt-0'>Rent A Desk, Madhapur, HITEC City, Hyderabad, Telangana 500081</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

<div className='followusDiv'>
<h6>Follow Us</h6>

{/* <div className="row d-flex">
      <div className="col">
        <img src={linkedin}  alt='linkedin-icon' />
      </div>
      <div className="col">
      <img src={instagram} class="img-fluid" alt='instagram-icon' />
      </div>
      <div className="col">
      <img src={facebook} class="img-fluid" alt='facebook-icon' />
      </div>
      <div className="col">
      <img src={twitter} class="img-fluid" alt='twitter-icon' />
      </div>
    </div> */}

<div class="d-flex justify-content-center align-items-center gap-3 m-4">
  <a href="#" class="fa fa-linkedin followus-socialmediaIcons"></a>
  <a href="#" class="fa fa-instagram followus-socialmediaIcons"></a>
  <a href="#" class="fa fa-facebook followus-socialmediaIcons"></a>
  <a href="#" class="fa fa-twitter followus-socialmediaIcons"></a>
  </div>
</div>
       
          </div>

          </div>
  </div>
  </div>
  );
}

export default GetIntouch;
