import blub from '../../assests/images/blub.svg';
import uiux from '../../assests/images/uiux.svg';
import appdevelopment from '../../assests/images/appdevelopment.svg';
import android from '../../assests/images/android.svg';
import tablersettingscode from '../../assests/images/tabler_settings-code.svg';
import deployment from '../../assests/images/deployment.png';
import digitalbg from '../../assests/images/digitalbg.svg';
import call from '../../assests/images/call.svg';
import email from '../../assests/images/email.svg';
import location from '../../assests/images/location.svg';
import contactus from '../../assests/images/contactus.svg'
import './service.css';

function Contactus() {
  return (
    <div>
        <div className='text-center footerDiv servicelabel'>
            <h4>Contact</h4>
            <p>Home / Services</p>
        </div>
         <div className="row containerDiv">
    <div className='col-md-6 text-start bgDivLight paraalign'>
      <h2 className='m-0 headerLabels1'>\ About to discuss \</h2>
      <h2 className='text-dark headerLabels my-2'>Contact Us</h2>
      <p className='text-dark paragraph my-4'>Please feel free to reach out to us using the contact information provided below. We are here to assist you and will respond to your inquiry as promptly as possible.</p>
    </div>
    <div className="col-md-6">
     <img src={contactus} alt="streetjob" width={550}/>
    </div>
   
         </div>
        <div className="row containerDiv mt-4 margintopcontent">
        <h2 className='mt-4 headerLabels1 text-center'>\ CONTACT DETAILS \</h2>
        <h2 className='my-2 headerLabels text-center'>Our Information</h2>
        <div className='row mt-4'>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={blub} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Our Address</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Rent A Desk, Madhapur, HITEC City, Hyderabad, Telangana 500081</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={uiux} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Phone Number</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>+91 9985402433</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={tablersettingscode} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Email Address</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>sales@sjsolutions.in</p>
            </div>
            </div>
        </div>
        </div>
        </div>

  <div className='row bgcolorgetIntouch m-0 container-fluid'>
  <div className="getincontainerDiv">
  <h2 className='mt-4 headerLabels1 text-center'>\ Get In Touch \</h2>
  <h2 className='my-2 text-center labeltext contacttext mb-4'>Hey! Let’s Talk, Get a Free Consultation Now</h2>
  <div className='row contactbottom'>
    <div className='col-7 box1 me-3'>
      <input type="text" placeholder='Name' className='contactinputfield'/>
      <input type="text" placeholder='Email' className='contactinputfield'/>
      <input type="text" placeholder='Phone' className='contactinputfield'/>
      <textarea type="text" placeholder='Your message' className='contactinputfield'/>
      <div className='text-start ms-3 my-4'>
      <button type="button" className='primary submitbtn'>Send Now</button>
      </div>
    </div>
    <div className='col-4 box1'>
        <div className='row'>
        <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                      <div>
                      <img src={call} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                      </div>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Call Anytime</h5>
                    <p className='text-start mb-0 mt-0'>+91 99854 02433</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={email} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Send Email</h5>
                    <p className='text-start mb-0 mt-0'>sales@sjsolutions.in</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={location} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Visit Us</h5>
                    <p className='text-start mb-0 mt-0'>Rent A Desk, Madhapur, HITEC City, Hyderabad, Telangana 500081</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
          </div>
          </div>
  </div>
  </div>

    </div>
  );
}

export default Contactus;
