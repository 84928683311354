import Ellipse4 from '../../assests/images/Ellipse4.svg';
import Ellipse5 from '../../assests/images/Ellipse5.svg';
import Ellipse6 from '../../assests/images/Ellipse6.svg';
import quote from '../../assests/images/quote.svg';
import './testimonial.css';

function Testimonial() {

  return (
    <div className='bgImgTestimonial'>
 <div className='row testimonialcontainer container-fluid'>
           <h2 className='mt-4 headerLabels1 text-center' style={{paddingTop: '2%'}}>\ From our Customers \</h2>
           <h2 className='mb-4 headerLabels text-center'>Testimonials</h2>
        <div className='col-4'>
            <div className='cardBg'>
            <div className='d-flex justify-content-between px-3 py-2'>
            <img src={Ellipse4} alt="Ellipse6" />
            <img src={quote} alt="quote" width={36}/>
            </div>
            <div>
            <p className='text-start cardpara px-3'>I am really happy to work with SJ Software Solutions. The project manager as a single point of contact is doing great job in bringing all things together. The QA Automation team is really helpful in support of regular delivery process of the product.</p>
            <p className='mb-0 text-start cardname px-3'>Oliver Karl</p>
            <p className='mb-3 text-start cardposition px-3'>CEO</p>
            </div>
            </div>
        </div>
        <div className='col-4'>
        <div className='cardBg'>
        <div className='d-flex justify-content-between px-3 py-2'>
            <img src={Ellipse5} alt="Ellipse5" />
            <img src={quote} alt="quote" width={36}/>
            </div>
            <div>
            <p className='text-start cardpara px-3'>SJ Software Solutions. listened to our needs and made sure we were getting the correct value for the service provided. Their leadership recognized our requirements and their groups efforts materialized. It became a very cohesive relationship.</p>
            <p className='mb-0 text-start cardname px-3'>Kelly Ork</p>
            <p className='mb-3 text-start cardposition px-3'>CEO</p>
            </div>
            </div>
        </div>
        <div className='col-4'>
        <div className='cardBg'>
        <div className='d-flex justify-content-between px-3 py-2'>
            <img src={Ellipse6} alt="Ellipse6" />
            <img src={quote} alt="quote" width={36}/>
            </div>
            <div>
            <p className='text-start cardpara px-3'>I chose SJ Software Solutions. because we received great customer service from the first inquiry, the team is very professional, the work agreement is reassuring, and the testimonials on the website were good. Overall, a very professional experience, great process, and really friendly and committed team.</p>
            <p className='mb-0 text-start cardname px-3'>Mallory Aurphy</p>
            <p className='mb-3 text-start cardposition px-3'>CEO</p>
            </div>
            </div>
        </div>


    </div>
    </div>
   
  );
}

export default Testimonial;
