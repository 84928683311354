import research from '../../assests/images/research.svg';
import analytics from '../../assests/images/analytics.svg';
import deveops from '../../assests/images/deveops.svg';
import test from '../../assests/images/test.svg';
import webdesign from '../../assests/images/webdesign.svg';
import uiux from '../../assests/images/uiux.svg';
import ai from '../../assests/images/ai.svg';
import appdevelopment from '../../assests/images/appdevelopment.svg';
import android from '../../assests/images/android.svg';
import ios from '../../assests/images/ios.svg';
import '../funfact/funfact.css';
import './planningservices.css';


function Planningservices() {
    return (

        <div className='bgImgPlanning'>
 <div className="row planningServiceDiv">
        
        <h2 className='mt-2 headerLabels1 text-center'>\ Planning \</h2>
        <h2 className='mt-1 headerLabels text-center'>Our Process</h2>
        <div className='row mt-2'>
        <div className='col-lg-3'>
        <div className='expCard'>
            <div>
            <img src={research} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Research</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Look into recent developments in AI and ML algorithms, applications, and frameworks. Areas such as natural language processing, computer vision, and reinforcement learning are especially active.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-3'>
        <div className='expCard'>
            <div>
            <img src={analytics} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Analytics</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Real-time analytics processes data as it is generated, providing immediate insights and enabling quick decision-making. In technology, real-time analytics is used for monitoring system performance, detecting security threats, and optimizing user experiences.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-3'>
        <div className='expCard'>
            <div>
            <img src={deveops} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Development</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>This involves creating applications, websites, and other software solutions. Depending on your company's needs, you might develop desktop applications, web applications, mobile apps, or even embedded software for IoT devices.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-3'>
        <div className='expCard'>
            <div>
            <img src={test} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Test</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Develop a comprehensive test plan that outlines the testing objectives, scope, resources, timelines, and responsibilities. Identify the types of testing to be performed and prioritize them based on risk and criticality.</p>
            </div>
            </div>
        </div>
       
        </div>
        <h2 className='mt-4 headerLabels1 text-center'>\ Services \</h2>
        <h2 className='my-2 headerLabels text-center'>Our Expertice</h2>
        <div className='row mt-3'>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={webdesign} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Web Design / Development</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Web designers blend aesthetics with functionality, ensuring that every element on a web page serves a purpose. web development is the process of bringing web designs to life through coding and programming.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={uiux} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>UI/UX</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>User Interface (UI) and User Experience (UX) design are integral components of creating user-centrist digital products and services. Let's explore these disciplines and understand how they converge to shape exceptional user journeys.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={ai} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>AI</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>AI refers to the development of computer systems that can perform tasks that typically require human intelligence. Artificial Intelligence is a trans-formative force that promises to revolutionize nearly every aspect of our lives.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4 pt-3'>
        <div className='expCard cardheight'>
            <div>
            <img src={appdevelopment} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>App development</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Mobile app development (iOS, Android), Transparency, collaboration, and excellence are at the core of our app development process. From concept to launch, we follow a structured approach that ensures the successful delivery of your project.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4 pt-3'>
        <div className='expCard cardheight'>
            <div>
            <img src={android} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Android</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>we specialize innovative, and user-friendly mobile applications. With millions of users worldwide, Android offers unparalleled opportunities for businesses to reach and engage their target audience.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4 pt-3'>
        <div className='expCard cardheight'>
            <div>
            <img src={ios} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>IOS</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Our iOS development services are designed to help you leverage these opportunities, delivering bespoke solutions that elevate your brand and exceed your expectations.</p>
            </div>
            </div>
        </div>
        </div>
    </div>

        </div>
       
    );
}

export default Planningservices;
