import team1 from '../../assests/images/team1.png';
import team2 from '../../assests/images/team2.png';
import team3 from '../../assests/images/team3.png';
import team4 from '../../assests/images/team4.png';
import team5 from '../../assests/images/team5.png';
import team6 from '../../assests/images/team6.png';
import team7 from '../../assests/images/team7.png';
import team8 from '../../assests/images/team8.png';
import team9 from '../../assests/images/team9.png';
import team10 from '../../assests/images/team10.png';
import './team.css';

function Team() {

  return (
  <div id="team" className='mt-4 bgImgTeam row'>
   <h2 className='mt-4 headerLabels1 text-center'>\ Meet Our Team \</h2>
   <h2 className='my-2 headerLabels text-center'>Our Leaders & Team Members</h2>
   <div className='row d-flex justify-content-center' style={{marginTop: '5rem'}}>
     <div className='col-2 leadercard mx-3'>
      <img src={team1} alt="team1" className='teamcard'/>
      <p className='mt-3 mb-0 cardtext'>Sriram Nagulapati</p>
      <p className='cardtext text-black' style={{color:'#000'}}>CEO</p>
      <div className='d-flex justify-content-center mb-3'>
     <a href="#" className="fa fa-linkedin mx-2" style={{textDecoration:'none',color:'#2066AE'}}></a>
     <a href="#" className="fa fa-instagram" style={{textDecoration:'none',color:'#E96C6C'}}></a>
     </div>
     </div>
     <div className='col-2 leadercard'>
     <img src={team2} alt="team1" className='teamcard'/>
     <p className='cardtext mt-3 mb-0'>Alekhya Nagulapati</p>
     <p className='cardtext text-black' style={{color:'#000'}}>Managing Director</p>
     <div className='d-flex justify-content-center mb-3'>
     <a href="#" className="fa fa-linkedin mx-2" style={{textDecoration:'none',color:'#2066AE'}}></a>
     <a href="#" className="fa fa-instagram" style={{textDecoration:'none',color:'#E96C6C'}}></a>
     </div>
     </div>
   </div>
   <div className='d-flex mt-4 mb-4'>
     <div>
       <img src={team3} alt="team3" className='imgcard'/>
       <p className='mt-2 cardname mb-0'>Ajay Kumar</p>
       <p style={{color:'#000',fontSize:'12px'}}>Team Lead</p>
     </div>
     <div>
       <img src={team4} alt="team4" className='imgcard'/>
       <p className='mt-2 cardname mb-0'>Gayathri</p>
       <p style={{color:'#000',fontSize:'12px'}}>Software Engineer</p>
     </div>
     <div>
       <img src={team5} alt="team5" className='imgcard'/>
       <p className='mt-2 cardname mb-0'>AnandKumar</p>
       <p style={{color:'#000',fontSize:'12px'}}>QA Engineer</p>
     </div>
     <div>
       <img src={team6} alt="team6" className='imgcard'/>
       <p className='mt-2 cardname mb-0'>Anusha</p>
       <p style={{color:'#000',fontSize:'12px'}}>QA Engineer</p>
     </div>
     <div>
       <img src={team7} alt="team7" className='imgcard'/>
       <p className='mt-2 cardname mb-0'>Kiran Guptha</p>
       <p style={{color:'#000',fontSize:'12px'}}>Assistant Software Engineer</p>
     </div>
     <div>
       <img src={team8} alt="team8" className='imgcard'/>
       <p className='mt-2 cardname mb-0'>Soujanya</p>
       <p style={{color:'#000',fontSize:'12px'}}>QA Engineer</p>
     </div>
     <div>
       <img src={team9} alt="team9" className='imgcard'/>
       <p className='mt-2 cardname mb-0'>Nagendra</p>
       <p style={{color:'#000',fontSize:'12px'}}>Assistant Software Engineer</p>
     </div>
     <div>
       <img src={team10} alt="team10" className='imgcard'/>
       <p className='mt-2 cardname mb-0'>Chaitanya</p>
       <p style={{color:'#000',fontSize:'12px'}}>Software Engineer</p>
     </div>

   </div>


  </div>
  );
}

export default Team;
