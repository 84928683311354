import react from '../../assests/images/react.svg';
import wordpress from '../../assests/images/wordpress.svg';
import './career.css';


function Career() {
    return (

        <div className='bgImgCareer'>
 <div className="row containerDiv">
            <h2 className='mt-4 headerLabels1 text-center'>\ We are looking \</h2>
            <h2 className='my-2 headerLabels text-center'>Career</h2>
            <div className='row mt-4'>
            <div className='col-lg-3 d-flex'>
            <div className={'text-dark card mb-3 cardRadius careercard'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={react} className="img-fluid rounded-start iconcard bg-white" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>ReactJs Dev</h5>
                    <p className='text-start mb-0 mt-0'>1-5 Years of Exp.</p>
                    <p className='text-end my-2'>Apply now</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-3 d-flex'>
            <div className={'text-dark card mb-3 cardRadius careercard'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={react} className="img-fluid rounded-start iconcard bg-white" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start'>Wordpress Dev</h5>
                    <p className='text-start mb-0 mt-0'>1-5 Years of Exp.</p>
                    <p className='text-end my-2'>Apply now</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-3 d-flex'>
            <div className={'text-dark card mb-3 cardRadius careercard'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={wordpress} className="img-fluid rounded-start iconcard bg-white" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start'>ReactJs Dev</h5>
                    <p className='text-start mb-0 mt-0'>1-5 Years of Exp.</p>
                    <p className='text-end my-2'>Apply now</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-3 d-flex'>
            <div className={'text-dark card mb-3 cardRadius careercard'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={wordpress} className="img-fluid rounded-start iconcard bg-white" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start'>Python Developer</h5>
                    <p className='text-start mb-0 mt-0'>1-5 Years of Exp.</p>
                    <p className='text-end my-2'>Apply now</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <div className='historybg'>
            <h2>“Some of the History of Our Company is that<br/>
              we are Catching up through Video”</h2>
              <button type="primary" className='getIntouchbtn mt-4' style={{width:'13%',margin:'auto'}}>
          <a className="nav-link" href="#getintouch" style={{padding: '4px 2px',fontSize: '15px'}}>Get In Touch</a>
          </button>
            </div>
        </div>

        </div>
       
    );
}

export default Career;
