import Blub from '../../assests/images/blub.svg';
import Group1 from '../../assests/images/Group1.svg';
import group2 from '../../assests/images/group2.svg';
import group3 from '../../assests/images/group3.svg';
import Black from '../../assests/images/Black.svg';
import images1 from '../../assests/images/image1.svg';
import images2 from '../../assests/images/image2.svg';
import images3 from '../../assests/images/image3.svg';
import images4 from '../../assests/images/image4.svg';
import images5 from '../../assests/images/image5.svg';
import './funfact.css';
import { useState } from 'react';


function FunFact() {
    const [isAnimating, setIsAnimating] = useState(false);

    const handleClick =() =>{
        setIsAnimating(true);
        setTimeout(() => {
            setIsAnimating(false);
        }, 10000); // Stop animation after 10 seconds
       
    }
    return (
        <div >
           <div className='bgImgFunfact'>
            
            <div className="row funfactDiv">

            <h2 className='mt-4 headerLabels1 text-center'>\FUN FACTS \</h2>
            <h2 className='my-2 headerLabels text-center'>We have over 14 years of experience</h2>
            <p>We are young vibraint team hoaving good knowledge on various technologies</p>
            <div className='row mt-4'>
            <div className='col-lg-3 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={Blub} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>5</h5>
                    <p className='text-start mb-0 mt-0'>Business Ideas</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-3 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={Black} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start'>5</h5>
                    <p className='text-start mb-0 mt-0'>Business Ideas</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-3 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={Blub} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start'>5</h5>
                    <p className='text-start mb-0 mt-0'>Business Ideas</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-3 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={Black} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start'>5</h5>
                    <p className='text-start mb-0 mt-0'>Business Ideas</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <h2 className='mt-4 headerLabels1 text-center'>\ WHY CHOOSE US \</h2>
            <h2 className='my-2 headerLabels text-center'>Our experience will help you create a quality product</h2>
            <div className='row mt-4'>
            <div className='col-lg-4'>
            <div className='expCard'>
                <div>
                <img src={Group1} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
                <p className='text-start ms-4 cardlabel'>Best Industry Experts</p>
                <div className='d-flex ms-3'>
                    <div className='line1'></div>
                    <div className='line2'></div>
                </div>
                <p className='mt-4 text-start cardpara mx-4'>We have deep understanding of industry trends, challenges, and best practices, and the ability to drive positive change within their industries.</p>
                </div>
                </div>
            </div>
            <div className='col-lg-4'>
            <div className='expCard'>
                <div>
                <img src={group2} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
                <p className='text-start ms-4 cardlabel'>Fast & Effective Solutions</p>
                <div className='d-flex ms-3'>
                    <div className='line1'></div>
                    <div className='line2'></div>
                </div>
                <p className='mt-4 text-start cardpara mx-4'>Swiftly and efficiently finding and implementing strategies that deliver the desired results, effectively addressing the problem or challenge at hand.</p>
                </div>
                </div>
            </div>
            <div className='col-lg-4'>
            <div className='expCard'>
                <div>
                <img src={group3} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
                <p className='text-start ms-4 cardlabel'>Quick Support</p>
                <div className='d-flex ms-3'>
                    <div className='line1'></div>
                    <div className='line2'></div>
                </div>
                <p className='mt-4 text-start cardpara mx-4'>Providing prompt assistance, guidance, or resolution to an issue or problem of client inquiries, requests, or technical difficulties.</p>
                </div>
                </div>
            </div>
           
            </div>
            

            </div>

            </div> 
            <div className='borderLine'></div>
            <h2 className='headerLabels1 text-center'> \ OUR WORKS \</h2>
            <div className='ImagesTransitionContainer' onClick={handleClick}>

            <div className={`ImagesTransition row  mt-3 ${isAnimating ? 'animate' : ''}`}>
               <img src={images5} alt="img5" className='imgwidth'/>
               <img src={images1} alt="img1" className='imgwidth'/>
               <img src={images2} alt="img2" className='imgwidth'/>
               <img src={images3} alt="img3" className='imgwidth'/>
               <img src={images4} alt="img4" className='imgwidth'/>
               

               {/* Duplicate images for looping */}
               <img src={images5} alt="img5" className='imgwidth'/>
               <img src={images1} alt="img1" className='imgwidth'/>
               <img src={images2} alt="img2" className='imgwidth'/>
               <img src={images3} alt="img3" className='imgwidth'/>
               <img src={images4} alt="img4" className='imgwidth'/>
        
            </div>
            </div>
            
       

        </div>
       
    );
}

export default FunFact;
