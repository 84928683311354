import blub from '../../assests/images/blub.svg';
import uiux from '../../assests/images/uiux.svg';
import appdevelopment from '../../assests/images/appdevelopment.svg';
import android from '../../assests/images/android.svg';
import tablersettingscode from '../../assests/images/tabler_settings-code.svg';
import deployment from '../../assests/images/deployment.png';
import webdevelopment from '../../assests/images/webapp.png';
import call from '../../assests/images/call.svg';
import email from '../../assests/images/email.svg';
import location from '../../assests/images/location.svg';
import './service.css';
import NavbarHeader from '../navbar/navbar';

function Webdevelopment() {
  return (
    <div>
        <NavbarHeader/>
        <div className='text-center footerDiv servicelabel'>
            <h4>Services</h4>
            <p>Home / Services</p>
        </div>
        <div className='bgImgWhatWeDo'>
        <div className="row webAppDiv">
    <div className='col-md-4 text-start bgDivLight servicesParaAlign'>
      <h2 className='m-0 headerLabels1'>\ What We Do \</h2>
      <h2 className='text-dark headerLabels my-2'>Website Development</h2>
      <p className='text-dark paragraph my-4'>Web design is the process of creating websites. Includes webpage Designing, User Interface Designing, content writing and Development. The websites developed in Static, Dynamic and eCommerce platforms.</p>
    </div>
    <div className="col-md-8">
     <img src={webdevelopment} alt="streetjob" width="100%"/>

    </div>
   
         </div>
        </div>
         

         <div className='bgImgHowWeDo'>
         <div className="row containerDiv margintopcontent">
        <h2 className='mt-4 headerLabels1 text-center'>\ HOW WE DO  \</h2>
        <h2 className='my-2 headerLabels text-center'>Our Approach for app development</h2>
        <div className='row mt-4'>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={blub} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Idea and Planning</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>The first step is to define the purpose and goals of the app. Identify your target audience and the problem your app will solve. Plan the features, functionality, and design of the app.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={uiux} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Wire framing and Design</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Create a basic layout of the app's user interface (UI) through wireframing.Pay attention to usability and ensure a consistent user interface design across different screens</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={tablersettingscode} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Development</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Choose a development approach for iOS & Android, you can opt for cross-platform frameworks like Flutter, which allow developing apps for multiple platforms using a single codebase.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4 mt-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={appdevelopment} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Backend Development</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>This involves setting up servers, APIs, and databases to handle data storage, user authentication, and other server-side operations.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4 mt-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={android} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Testing</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>Test the app on different devices, operating system versions, and screen sizes to ensure compatibility and a smooth user experience.</p>
            </div>
            </div>
        </div>
        <div className='col-lg-4 mt-4'>
        <div className='expCard cardheight'>
            <div>
            <img src={deployment} className="img-fluid rounded-start iconcard expiconcard d-block" alt="icon" width={40} style={{margin: '18px 25px'}}/>
            <p className='text-start ms-4 cardlabel'>Deployment</p>
            <div className='d-flex ms-3'>
                <div className='line1'></div>
                <div className='line2'></div>
            </div>
            <p className='mt-4 text-start cardpara mx-4'>App distribution. For iOS, enroll in the Apple Developer Program and submit the app. For Android, you can upload the app to the Google Play Store.</p>
            </div>
            </div>
        </div>
        </div>
        </div>
         </div>
        

        <div className="row containerDiv" style={{marginTop:'5rem'}}>
    <div className='col-md-4 text-start bgDivLight paraalign' style={{marginRight:'9rem'}}>
      <h2 className='m-0 headerLabels1'>\ EXPERTISE IN \</h2>
      <h2 className='text-dark headerLabels my-2'>Our Top Skills</h2>
      <p className='text-dark paragraph my-4'>Our core strenght is to understand client requiremnt in detail and suggest the solution based on client budget
      Industry standards with best practices</p>
    </div>
    <div className="col-md-6">
        <div className='d-flex justify-content-between'>
        <h5 className='text-start'>Design</h5>
        <h5 className='text-end'>90%</h5>
        </div>
        <div className="progress">
        <div className="progress-bar" role="progressbar" style={{width: "90%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div className='d-flex justify-content-between mt-3'>
        <h5 className='text-start'>Development</h5>
        <h5 className='text-end'>95%</h5>
        </div>
        <div className="progress">
        <div className="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div className='d-flex justify-content-between mt-3'>
        <h5 className='text-start'>Testing</h5>
        <h5 className='text-end'>95%</h5>
        </div>
        <div className="progress">
        <div className="progress-bar" role="progressbar" style={{width: "95%"}} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
        <div className='d-flex justify-content-between mt-3'>
        <h5 className='text-start'>Delivery</h5>
        <h5 className='text-end'>95%</h5>
        </div>
        <div className="progress">
        <div className="progress-bar" role="progressbar" style={{width: "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
        </div>
    </div>
   
  </div>

  <div className='bgGetTouchImg'>

  <div className='row  m-0 container-fluid'>
  <div class="getincontainerDiv">
  <h2 className='mt-4 headerLabels1 text-center'>\ Get In Touch \</h2>
  <h2 className='my-2 text-center labeltext contacttext mb-4'>Hey! Let’s Talk, Get a Free Consultation Now</h2>
  <div className='row contactbottom'>
    <div className='col-7 box1 me-3'>
      <input type="text" placeholder='Name' className='contactinputfield'/>
      <input type="text" placeholder='Email' className='contactinputfield'/>
      <input type="text" placeholder='Phone' className='contactinputfield'/>
      <textarea type="text" placeholder='Your message' className='contactinputfield'/>
      <div className='text-start ms-3 my-4'>
      <button type="button" className='primary submitbtn'>Send Now</button>
      </div>
    </div>
    <div className='col-4 box1'>
        <div className='row'>
        <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                      <div>
                      <img src={call} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                      </div>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Call Anytime</h5>
                    <p className='text-start mb-0 mt-0'>+91 99854 02433</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={email} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Send Email</h5>
                    <p className='text-start mb-0 mt-0'>sales@sjsolutions.in</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            <div className='col-lg-12 d-flex'>
            <div className={'text-dark card mb-3 cardRadius funcard bg-white  border-white'} >
                <div className="row g-0">
                    <div className="col-4">
                    <img src={location} className="img-fluid rounded-start iconcard" alt="icon" width={40} style={{ margin: '13px'}}/>
                    </div>
                    <div className="col-8">
                    <div className="card-body statuscard">
                    <h5 className='text-start mb-0 mt-2'>Visit Us</h5>
                    <p className='text-start mb-0 mt-0'>Rent A Desk, Madhapur, HITEC City, Hyderabad, Telangana 500081</p>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
          </div>
          </div>
  </div>
  </div>
  </div>
  

    </div>
  );
}

export default Webdevelopment;
