import blog1 from '../../assests/images/blog1.svg';
import blog2 from '../../assests/images/blog2.svg';
import blog3 from '../../assests/images/blog3.svg';
import blog4 from '../../assests/images/blog4.svg';
import blog5 from '../../assests/images/blog5.svg';
import blog6 from '../../assests/images/blog6.svg';
import './blogs.css';

function Blog() {
    return (

        <div className='bgImgOurBlog'>
             <div className="row containerDiv">
        
        <h2 className='mt-4 headerLabels1 text-center'>\ Our Blog \</h2>
        <h2 className='my-2 headerLabels text-center'>Latest Post</h2>
        <div className='row mt-4'>
           <div className='col-4'>
             <div className='blogcard'>
                <img src={blog1} alt="blog1" className="blogimgwidth"/>
                <p className='text-start blogpara'>The most Popular Business
                Of the Year</p>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <img src={blog4} alt="blog4" className='bloguseimg'/>
                        <span className='blogname'>Ranold Jeff.</span>
                    </div>
                    <p className='p-0 carddate'>May 4th, 2022</p>
                </div>
             </div>
           </div>
           <div className='col-4'>
             <div className='blogcard'>
                <img src={blog2} alt="blog1" className="blogimgwidth"/>
                <p className='text-start blogpara'>The most Popular Business
                Of the Year</p>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <img src={blog5} alt="blog4" className='bloguseimg'/>
                        <span className='blogname'>Patricia Anderson</span>
                    </div>
                    <p className='p-0 carddate mt-3'>May 4th, 2022</p>
                </div>
             </div>
           </div>
           <div className='col-4'>
             <div className='blogcard'>
                <img src={blog3} alt="blog1" className="blogimgwidth"/>
                <p className='text-start blogpara'>The most Popular Business
                Of the Year</p>
                <div className='d-flex justify-content-between mt-4'>
                    <div>
                        <img src={blog6} alt="blog4" className='bloguseimg'/>
                        <span className='blogname'>Elaine Luna</span>
                    </div>
                    <p className='p-0 carddate'>May 4th, 2022</p>
                </div>
             </div>
           </div>
        </div>
    </div>
        </div>
       
    );
}

export default Blog;
